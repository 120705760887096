import { useMemo } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import createForm from "./create-form";
import { reduceObj, pipes, isEmptyObj, throwErr, isEmptyArr } from "./helper";
import {
  initForm as initFormAction,
  updateElement as updateElementAction,
  setDefaultValue as setDefaultValueAction,
  selectState as selectFormState,
  selectInput as selectInputState,
  selectDefaultValue as selectFormValueState,
} from "./form-slice";

function toFormValue({ elements } = {}) {
  return (
    elements &&
    Object.entries(elements)
      .map(([k, v]) => ({
        [k]: v.value,
      }))
      .reduce(reduceObj, {})
  );
}

function toFormError({ elements }) {
  return Object.entries(elements)
    .filter(([, v]) => v.isError)
    .map(([k, v]) => ({ [k]: v.errorText }));
}

function toFormState({ name, touched, elements }) {
  return {
    name,
    touched,
    elements: Object.entries(elements)
      .map(([name, { value, isError, errorText, touched }]) => ({
        [name]: { value, isError, errorText, touched },
      }))
      .reduce(reduceObj, {}),
  };
}

export function useInputControl(element) {
  throwErr(isEmptyObj(element), "Element field required in useInputControl.");
  const dispatch = useDispatch();
  const { name, validate } = element;
  const event = useMemo(
    function mapEvent() {
      return {
        setValue: pipes(function checkValue({ value, touched }) {
          return { name, data: validate(value, touched || false) };
        }, bindActionCreators(updateElementAction, dispatch)),
      };
    },
    [name]
  );
  const state = useSelector(selectInputState(name));
  return { name, ...state, ...event };
}

export function useFormControl(formBuilder) {
  throwErr(
    isEmptyObj(formBuilder),
    `Error in useFormControl. formBuilder is empty or null`
  );
  const dispatch = useDispatch();
  // const [value, setValue] = useState(formValue);
  const value = useSelector(selectFormValueState);

  return useMemo(
    function formControlMemo() {
      const { name, elements } = formBuilder;
      const form = createForm(name, elements, value);
      const dispatchDefaultValue = bindActionCreators(
        setDefaultValueAction,
        dispatch
      );
      const dispatchInitState = bindActionCreators(initFormAction, dispatch);
      dispatchInitState(toFormState(form));
      return {
        form,
        /**
         * Resets from and elements properties.
         * @param {Object} val.
         */
        reset(val = {}) {
          dispatchDefaultValue(val);
        },
      };
    },
    [formBuilder, value]
  );
}

export function useValidatorFormState() {
  const formState = useSelector(selectFormState);
  const errors = toFormError(formState);
  const value = toFormValue(formState);
  return { ...formState, value, errors, isError: !isEmptyArr(errors) };
}

export function useValidatorFormAction() {
  const dispatch = useDispatch();
  const setDefaultValue = bindActionCreators(setDefaultValueAction, dispatch);
  return { setDefaultValue };
}
