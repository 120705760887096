import React from "react";
import OneX from "../utils/OneX";
import { v4 as uuid } from "uuid";

/**
 * Move screen to top. especially using in back function.
 */
function scrollToTop() {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 60);
}

/**
 * Higher order component to resolve oneX render problem.
 * @param {} params
 */
export default function onexConnect(wrap = false) {
  const styleClass = wrap === true ? "-oneX-container" : "";
  return function connect(Component) {
    return function OnexComponent(props) {
      const id = uuid();
      const thisRef = React.useRef();
      React.useEffect(() => {
        OneX.element.add(thisRef);
        scrollToTop();
        return () => {
          OneX.element.remove(thisRef);
        };
      }, []);

      return (
        <article className={styleClass} ref={thisRef} id={id}>
          <Component {...props} />
        </article>
      );
    };
  };
}
