import React, { Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Search,
  Result,
  TechnicalError,
  AuthError,
  SessionTimeout,
} from "../features";
import { Header, ModelSpinner } from "../components";
import { selectInProgress, init, selectView } from "./app-slice";
import { PAGE } from "../constant";
export default function App() {
  // const isAuthenticated = useIsAuthenticated();
  // const prefix = process.env.NODE_ENV === "development" ? "" : "../ars-api";

  const dispatch = useDispatch();

  const view = useSelector(selectView);

  const inProgress = useSelector(selectInProgress);
  React.useEffect(() => {
    dispatch(init());
    return () => {};
  }, []);

  return (
    <>
      <main>
        <Header auth={PAGE.UN_AUTH !== view} />
        <Suspense fallback={<section>Loading...</section>}>
          {inProgress === true && <ModelSpinner />}
          {PAGE.SEARCH === view && <Search />}
          {PAGE.RESULT === view && <Result />}
          {PAGE.ERROR === view && <TechnicalError />}
          {PAGE.UN_AUTH === view && <AuthError />}
          <SessionTimeout />
        </Suspense>
        <article className="mt-40">&nbsp;</article>
      </main>
    </>
  );
}
