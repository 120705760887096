/**
 * Constant for page components.
 */
export const PAGE = Object.freeze({
  LOADING: 0,
  SEARCH: 1,
  RESULT: 2,
  RESULT_NOT_FOUND: 3,
  ERROR: -1,
  UN_AUTH: -2,
});

/**
 * List of HTTP codes.
 */
export const HTTP_CODE = Object.freeze({
  // BAD_REQUEST: 400,
  UN_AUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  // UN_PROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
});
