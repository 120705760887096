import React from "react";
import { ErrorText } from "./error";
import { util, useInputControl } from "../validator";
const { throwErr, isEmptyObj } = util;

export default function Text({
  id,
  label,
  className,
  maxlength,
  dataFormatter,
  pattern,
  placeholder,
  element,
}) {
  throwErr(isEmptyObj(element), "Element property required in Text.");
  const { name, value, isError, errorText, touched, setValue } =
    useInputControl(element);
  const error = touched && isError;
  const _id = id || name;
  const styleClass = `${className} ${(error && "-oneX-widget--err") || ""}`;
  const thisRef = React.useRef();

  return (
    <div className={styleClass} aria-labelledby={id} ref={thisRef}>
      <label
        htmlFor={_id}
        className="-oneX-textfield__floatingLabel -oneX--floatUp"
      >
        {label || name}
      </label>
      <input
        id={_id}
        name={name}
        maxLength={maxlength}
        value={value}
        type="text"
        required
        className="-oneX-textfield--floating-input"
        autoComplete="off"
        aria-required="true"
        aria-invalid={error ? "true" : "false"}
        aria-describedby={`input-${name}`}
        data-formatter={dataFormatter}
        pattern={pattern}
        placeholder={placeholder}
        onChange={(e) => setValue({ value: e.target.value, touched })}
        onBlur={(e) => setValue({ value: e.target.value, touched: true })}
      />
      {error && <ErrorText id={`error-alert-${name}`} text={errorText} />}
    </div>
  );
}
