// other utils
const isEmptyString = (value) => !value || value.trim().length === 0;
/**
 * compose that execute function from right to left order.
 * @param  {...any} fns
 * @returns
 */
const compose =
  (...fns) =>
  (value) =>
    fns.reduceRight((acc, cur) => cur(acc), value);
const trim = (value) => (value ? value.trim() : value);
const trimNull = (value) =>
  isEmptyString(value) || value === "null" ? "" : value;
const uppercase = (value) => (value ? value.toUpperCase() : value);

const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;

const reduceObject = (acc, cur) => Object.assign(acc, cur);

const randomId = (value = Math.random()) =>
  value
    .valueOf()
    .toString(32)
    .replace(/[^a-zA-Z0-9]/g, "-");

const isFn = (fn) => fn && "function" === typeof fn;

/**
 * Return true if argument has string value.
 * @param {String} str
 * @returns
 */
const isStr = (str) => typeof str === "string" || str instanceof String;

/**
 * Return true if argument not array.
 * @param {*} arg
 * @returns
 */
const isArray = (arg) => typeof arg === "object" && Array.isArray(arg);

/**
 * Return true if arg has any element.
 * @param {Array} arr
 * @returns
 */
const hasElements = (arr) => isArray(arr) && 0 < arr.length;

const unescape = (() => {
  /** Used to map HTML entities to characters. */
  const htmlUnescapes = {
    "&amp;": "&",
    "&reg;": "®",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
  };
  /** Used to match HTML entities and HTML characters. */
  const reEscapedHtml = /&(?:amp|reg|lt|gt|quot|#(0+)?39);/g;
  const reHasEscapedHtml = RegExp(reEscapedHtml.source);
  return (str) => {
    return str && reHasEscapedHtml.test(str)
      ? str.replace(reEscapedHtml, (entity) => htmlUnescapes[entity] || "'")
      : str || "";
  };
})();

export {
  trimNull,
  compose,
  trim,
  uppercase,
  isEmptyObject,
  isEmptyString,
  isStr,
  isFn,
  hasElements,
  reduceObject,
  randomId,
  unescape,
};
export { default as OneX } from "./OneX";
