import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app-slice";
import formReducer from "../validator/form-slice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    form: formReducer,
  },
});
