function add(ele) {
  window.oneX && window.oneX.addElement(ele.current);
}

function remove(ele) {
  window.oneX && window.oneX.removeElement(ele.current);
}

function setProgressModel(flag) {
  if (flag) {
    window.oneX.Modal.showModal(window, "oneX-modal-1");
  } else {
    window.oneX.Modal.hideModal();
  }
}

const onex = {
  element: { add, remove },
  progressModel: {
    show: function () {
      setProgressModel(true);
    },
    hide: function () {
      setProgressModel(false);
    },
  },
};
export default onex;
