/**
 * Module build from from builder object.
 */
import { reduceObj, isEmptyObj, throwErr, isEmptyStr, pipes } from "./helper";

const { freeze, entries } = Object;

/**
 * Return undefined if all constrains matched otherwise returns unsatisfied object with error message.
 * @param  {[Array]} validatorFns [Array of validateFn]
 * @return {[Object|undefined]}   [Object when error found]
 * Usage:
 * const claimValidator = useValidator(
 *  { fn: validateFn.REQUIRED,   msg: "Claim Number is required", },
 *  { fn: validateFn.SIZE(11),   msg: "Please enter the first 9 digits of the claim number.", },
 *  { fn: validateFn.CLAIM_NUM,   msg: "Please enter the first 9 digits of the claim number.", },
 * );
 * expect(claimValidator("").msg).toBe("Claim Number is required");
 * expect(claimValidator("AA1235").msg).toBe("Please enter the first 9 digits of the claim number.");
 * expect(claimValidator("13-02Q1-65L") === undefined).toBeTruthy();
 */
function buildValidator(...validatorFns) {
  return (value) => validatorFns.find((validator) => validator.fn(value));
}

/**
 * Format input value according to chain of formatFn/(s).
 * Returns same value if not matches formatFn.regex
 * @param  {[Array]} formatFns one or more formatFns
 * @return {[String]} formatted string
 */
function buildFormatter(...formatters) {
  return (value) => pipes((str) => str || "", ...formatters)(value);
}

function validateInput(validator, formatter) {
  return function validate(aValue, touched = false) {
    const value = formatter(aValue);
    const error = validator && validator(value);
    const [isError, errorText] = (error &&
      !isEmptyStr(error.msg) && [true, error.msg]) || [false, ""];
    return { value, isError, errorText, touched };
  };
}

function buildInput(name, aValue, validators, filters, formatter) {
  const validate = validateInput(
    validators && buildValidator(...validators),
    buildFormatter(...filters, formatter)
  );
  const attrib = validate(aValue);
  return freeze({ name, ...attrib, validate });
}

/**
 * Function that builds form from builder object.
 * @param {String} name
 * @param {Object} elements
 * @param {Object} value
 * @returns {
 * name {String} form name.
 * touched {boolean},
 * elements {Object}
 * }
 */
export default function createForm(name, elements, value = {}) {
  throwErr(
    isEmptyObj(elements),
    `At lest one input field required in form ${name}.`
  );
  const fields = entries(elements)
    .map(function buildFormMapper([name, { validators, filters, format }]) {
      throwErr(isEmptyStr(name), "Field name require in form builder input!");
      // throwErr(validators.length === 0, `Atlest one validators required for ${name} input field.`);
      return freeze({
        [name]: buildInput(name, value[name], validators, filters, format),
      });
    })
    .reduce(reduceObj, {});

  return freeze({
    name,
    touched: value.touched === true,
    elements: freeze(fields),
  });
}
