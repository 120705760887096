import { v4 as uuid } from "uuid";
import { HTTP_CODE } from "../constant";
import { getSsoToken } from "../utils/getToken";

const prefix = ["dev", "env1", "test", "prod"].includes(
  process.env.REACT_APP_NODE_ENV
)
  ? process.env.REACT_APP_B2B_HOME_API
  : "../ars-api";

const reqId = uuid();
const TIME_OUT = 3 * 61000;
//const ssoToken = "--PASS-TOKEN-HERE--";

function getTimeout() {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), TIME_OUT);
  return controller;
}

async function _post(url = "", value = {}) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-Request-Id": reqId,
      "X-SF_SSO_TOKEN": getSsoToken(),
    },
    redirect: "error",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(value),
    signal: getTimeout().signal,
  });
  if (!response.ok) {
    const { status, statusText } = response;
    throw new Error(`${statusText}(${status}) at ${url}`, { cause: status });
  }
  return await response.json();
}

async function _get(url = "") {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-Request-Id": reqId,
      "X-SF_SSO_TOKEN": getSsoToken(),
    },

    redirect: "error",
    referrerPolicy: "no-referrer",
    signal: getTimeout().signal,
  });

  if (!response.ok) {
    const { status, statusText } = response;
    throw new Error(`${statusText}(${status}) at ${url}`, { cause: status });
  }
  return await response.json();
}

export async function init(data, { rejectWithValue }) {
  try {
    return await _get(`${prefix}/v1/`);
  } catch (error) {
    return rejectWithValue(error.cause || HTTP_CODE.INTERNAL_SERVER_ERROR);
  }
}

export async function fetchAddress(data = {}, { rejectWithValue }) {
  try {
    return await _post(`${prefix}/v1/findAddress`, data);
  } catch (error) {
    return rejectWithValue(error.cause || HTTP_CODE.INTERNAL_SERVER_ERROR);
  }
}

export async function fetchRates(data = {}, { rejectWithValue }) {
  try {
    return await _post(`${prefix}/v1/findRates`, data);
  } catch (error) {
    return rejectWithValue(error.cause || HTTP_CODE.INTERNAL_SERVER_ERROR);
  }
}
