import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { OneX } from "../../utils";
import { useFormControl, useValidatorFormState } from "../../validator";
import { PageTitle, PageError, PageTag } from "../../components";
import { Text, Select, Option, Submit, Button } from "../../elements";
import {
  fetchAddress,
  fetchRates,
  selectPageError,
  reset as resetAction,
} from "../../app/app-slice";
import formBuilder from "./search-form";

function PageLevelError({ text, tag }) {
  const { touched } = useValidatorFormState();

  return (
    touched === false && (
      <>
        <PageError text={text} />
        <PageTag name={`not-found-${tag}`} />
      </>
    )
  );
}

export default function Search() {
  const thisRef = React.useRef();
  const dispatch = useDispatch();
  const pageError = useSelector(selectPageError);
  const { form, reset: resetForm } = useFormControl(formBuilder);

  function onReset() {
    resetForm();
    dispatch(resetAction());
  }

  function onSubmit({ address, zipCode, radius, numOfShop }) {
    dispatch(fetchAddress({ address, zipCode }))
      .then(unwrapResult)
      .then(({ latitude, longitude }) =>
        dispatch(
          fetchRates({ latitude, longitude, zipCode, radius, numOfShop })
        )
      )
      .then(unwrapResult)
      .catch(() => resetForm({ address, zipCode, radius, numOfShop }));
  }

  React.useEffect(
    function mount() {
      OneX.element.add(thisRef);
      return function unMount() {
        OneX.element.remove(thisRef);
      };
    },
    [form]
  );

  return (
    <section id="search" className="-oneX-container" ref={thisRef}>
      <PageTitle
        title="Area Rate Search"
        summary="All fields are required. This tool should only be used in rural markets."
      />
      {pageError.valid && (
        <PageLevelError text={pageError.text} tag={pageError.tag} />
      )}
      <article className="-oneX-row mt-20">
        <Text
          element={form.elements["address"]}
          className="-oneX-col-12 -oneX-col-lg-9"
          label="Address"
          maxLength={100}
        />
      </article>
      <article className="-oneX-row mt-20">
        <Text
          element={form.elements["zipCode"]}
          className="-oneX-col-12 -oneX-col-md-4 -oneX-col-lg-3"
          label="Zip Code"
          maxLength={10}
        />
        <Text
          element={form.elements["radius"]}
          className="-oneX-col-12 -oneX-col-md-4 -oneX-col-lg-3"
          label="Radius (Miles)"
        />
        <Select
          id="numOfShop"
          element={form.elements["numOfShop"]}
          className="-oneX-col-12 -oneX-col-md-4 -oneX-col-lg-3"
          label="Number of Shops"
        >
          <Option value="" label="" />
          <Option list={["7", "20", "25", "50", "75", "100"]} />
        </Select>
      </article>
      <article className="-oneX-row mt-40">
        <div className="-oneX-col -oneX-offset-md-2">
          <Button
            id="resetShop"
            label="Reset"
            aria-label="Press reset button to clear values"
            onClick={onReset}
          />
        </div>
        <div className="-oneX-col -oneX-pull-2">
          <Submit
            id="searchShop"
            label="Find Shops"
            aria-label="Press Find Shops button to begin search"
            onClick={onSubmit}
          />
        </div>
      </article>
      <PageTag name="search" />
    </section>
  );
}
