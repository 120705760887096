import React from "react";

import { useValidatorFormState, util } from "../validator";

export function Button({
  name,
  id,
  label,
  onClick,
  className,
  disabled,
  ariaLabel,
}) {
  const styleClass = (className && className) || "-oneX-btn-secondary";

  return (
    <button
      id={id}
      name={name || id}
      className={styleClass}
      disabled={disabled || false}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default function Submit({
  name,
  id,
  label,
  onClick,
  className,
  disabled,
  ariaLabel,
}) {
  const { value, isError } = useValidatorFormState();
  const _onClick = util.isFun(onClick) && (() => onClick(value));
  return (
    <Button
      {...{
        name,
        id,
        label,
        onClick: _onClick,
        ariaLabel,
        disabled: disabled || isError,
        className: (className && className) || "-oneX-btn-primary",
      }}
    />
  );
}
