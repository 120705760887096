// Return true if empty or null
const REQUIRED = (value) => !value || value.trim().length === 0;

/**
 * return false if matches exact length.
 * @param {[regex]} regexObj [regular expression]
 * @param {[string]} value [Must be string value]
 */
const SIZE = (size) => (value) => value && value.length !== size;

const MIN = (size) => (value) => value && value.length < size;

const MAX = (size) => (value) => value && value.length > size;

/**
 * Returns true if value is empty, null and not matches expression.
 * @param {[regex]} regexObj [regular expression]
 * @param {[string]} value [Must be string value]
 */
const REGEX = (regexObj) => (value) => REQUIRED(value) || !regexObj.test(value);

// less than
const LT = (num) => (value) => {
  const _n = Number(value);
  return Number.isNaN(_n) ? true : _n < num;
};

// greater than
const GT = (num) => (value) => {
  const _n = Number(value);
  return Number.isNaN(_n) ? true : _n > num;
};

const NO_FUTURE_DATE = (value) => {
  const _calDate = new Date(value).getTime();
  return new Date().getTime() - _calDate < 0;
};

const EMAIL = REGEX(
  /^[a-z0-9_-]+(?:\.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([a-z]{2,4})$/i
);

const ZIP = REGEX(/^(\d{5})(-)?(\d{4})?$/);

const PHONE = REGEX(/^\(\d{3}\)\d{3}-\d{4}$/);

const INTEGER = REGEX(/^-?[0-9]*$/);

/**
 * Return true if not safe string.
 * @type {[type]}
 */
const SAFE = (
  (regexObj = /[^a-zA-Z0-9 (),._%@:!&"'\-$]/) =>
  (value) =>
    value && regexObj.test(value)
)();

const CLAIM_NUM = REGEX(/^([0-9]{2})(-)?([a-zA-Z0-9]{4})(-)?[a-zA-Z0-9]{3}$/);
/**
 * Return true if not number with comma(,).
 * @type {[type]}
 */
// const CURRENCY = (value) => value && Number.isNaN(Number(("" + value).replace(/,/g, "")));
const _currency = (symbol) => {
  const regS = REQUIRED(symbol) ? "" : `\\${symbol}`;
  const regex = new RegExp(
    `^${regS}(([1-9]\\d{0,2}(,\\d{3})*)|0)?(\\.\\d{1,2})?$`
  );
  return (value) => value && !regex.test(value);
};

const isDateEqual = (sDate, { year, month, day }) => {
  const date = new Date(sDate);
  return (
    date.getFullYear() !== parseInt(year) ||
    date.getMonth() + 1 !== parseInt(month) ||
    date.getDate() !== parseInt(day)
  );
};

const DATE = (value) => {
  if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(value)) {
    return true;
  }
  const [month, day, year] = value.split("-");
  const dateStr = `${year}-${month}-${day}T00:00:00`;
  return (
    Number.isNaN(Date.parse(dateStr)) ||
    isDateEqual(dateStr, { month, day, year })
  );
};

export default {
  REQUIRED,
  SIZE,
  MIN,
  MAX,
  REGEX,
  LT,
  GT,
  DATE,
  NO_FUTURE_DATE,
  EMAIL,
  ZIP,
  SAFE,
  CLAIM_NUM,
  CURRENCY: _currency(),
  CURRENCY_WITH_SYMBOL: _currency("$"),
  PHONE,
  INTEGER,
};
