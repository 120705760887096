import React from "react";

import { PageTitle, onexConnect, PageTag } from "../components";

const TITLE = "Technical Error";
const SUMMARY = `Something went wrong, we apologize for the inconvenience, please try again later.`;
/** Redirect URL when user not found. */
export const UN_AUTH_REDIRECT =
  "../../b2b-content/errors/error-invalid-server-403";

function Error({ title, summary, tag, children }) {
  const rootRef = React.useRef();
  React.useEffect(() => {
    setTimeout(() => rootRef.current.focus(), 1000);
    return () => {};
  }, []);
  return (
    <section
      id="errorSection"
      ref={rootRef}
      aria-labelledby="errPageTitle"
      tabIndex="-1"
    >
      <PageTitle title={title} summary={summary} />
      {children}
      <PageTag name={tag} />
    </section>
  );
}

function _TechnicalError() {
  function reload() {
    window.location.reload(true);
  }
  return (
    <Error title={TITLE} summary={SUMMARY} tag="error-technical">
      <article
        className="-oneX-row -oneX-justify-content-center mt-20"
        aria-labelledby="tryAgainBtn"
      >
        <div className="-oneX-col-auto mt-20">
          <button
            id="tryAgainBtn"
            name="tryAgainBtn"
            className="-oneX-btn-primary"
            aria-label="Try Again"
            onClick={reload}
          >
            Try Again
          </button>
        </div>
      </article>
    </Error>
  );
}

function _AuthError() {
  React.useEffect(() => {
    window.location.replace(UN_AUTH_REDIRECT);
    return () => {};
  }, []);
  return (
    <Error
      title="Invalid Server Request"
      summary="Sorry. This is an invalid server request."
      tag="error-unauth"
    />
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <PageTitle id="errPageTitle" title={TITLE} summary={SUMMARY} />;
    }
    return this.props.children;
  }
}

export const TechnicalError = onexConnect(true)(_TechnicalError);
export const AuthError = onexConnect(true)(_AuthError);

export { ErrorBoundary };
