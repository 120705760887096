import React from "react";
import { useSelector, useDispatch } from "react-redux";

import "./result.css";
import {
  selectOtherRates,
  selectShops,
  selectRateTableIndexes,
  setRateTableIndexes,
  setRateTableItemsPerPage,
  reset as resetAction,
} from "../app/app-slice";
import { PageTitle, Pagination, PageError, PageTag } from "../components";
import { Button } from "../elements";
import { useValidatorFormAction } from "../validator";

function Rate({ title, value }) {
  return (
    <ul className="avg-rate">
      <li>{title}</li>
      <li>$ {value}</li>
    </ul>
  );
}

function AverageRate({ title, rate }) {
  const { body, refinish, frame, paint } = rate;
  return (
    <div className="-oneX-col-12 -oneX-col-md-4 -oneX-col-lg-4">
      <header className="-oneX-heading--h6">{title}</header>
      <Rate title="Body Rate" value={body} />
      <Rate title="Refinish Rate" value={refinish} />
      <Rate title="Frame Rate" value={frame} />
      <Rate title="Paint Rate" value={paint} />
    </div>
  );
}

function AverageRateTable() {
  const { pcp, average, simpleMajority } = useSelector(selectOtherRates);
  return (
    <article className="-oneX-row">
      {pcp && <AverageRate title="PCP Rates" rate={pcp} />}
      {average && <AverageRate title="Average Rates" rate={average} />}
      {simpleMajority && (
        <AverageRate title="Simple Majority Rates" rate={simpleMajority} />
      )}
    </article>
  );
}

function ShopsRateTable({ start, end }) {
  const shops = useSelector(selectShops(start, end));

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 10);
    return () => {};
  }, [start, end]);

  return (
    <table className="-oneX-table--standard" aria-label="Standard table">
      <thead className="-oneX-thead">
        <tr className="-oneX-tr">
          <th className="-oneX-th--col w250">Shop Name</th>
          <th className="-oneX-th--col">
            Shop <br />
            Type
          </th>
          <th className="-oneX-th--col">
            Shop <br />
            Capacity
          </th>
          <th className="-oneX-th--col">
            Body <br />
            Rate
          </th>
          <th className="-oneX-th--col">
            Refinish <br />
            Rate
          </th>
          <th className="-oneX-th--col">
            Frame <br />
            Rate
          </th>
          <th className="-oneX-th--col">
            Paint and <br />
            Material Rate
          </th>
          <th className="-oneX-th--col">
            Distance <br />
            (Miles)
          </th>
        </tr>
      </thead>
      <tbody className="-oneX-tbody">
        {shops &&
          shops.map((e, i) => (
            <tr key={`shop-rate-${i}`} className="-oneX-tr">
              <td className="-oneX-th--row">{e.name}</td>
              <td className="-oneX-td">{e.type}</td>
              <td className="-oneX-td">{e.capacity}</td>
              <td className="-oneX-td">{e.body}</td>
              <td className="-oneX-td">{e.refinish}</td>
              <td className="-oneX-td">{e.frame}</td>
              <td className="-oneX-td">{e.paint}</td>
              <td className="-oneX-td">{e.distance}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

function ShopsRate() {
  const dispatch = useDispatch();

  const {
    itemsPerPage,
    total: totalShops,
    start,
    end,
  } = useSelector(selectRateTableIndexes);

  function setItemsPerPage(count) {
    dispatch(setRateTableItemsPerPage(count));
  }

  function setPageNumber(start, end) {
    dispatch(setRateTableIndexes({ start, end }));
  }

  if (totalShops > 0) {
    return (
      <>
        <ShopsRateTable start={start} end={end} />
        <Pagination
          id="shop-rate-pagination"
          totalItems={totalShops}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={setItemsPerPage}
          onPageNumberChange={setPageNumber}
        />
      </>
    );
  } else {
    return <PageError text="No result found!" />;
  }
}

export default function Result() {
  const dispatch = useDispatch();
  const { setDefaultValue: setDefaultFormValue } = useValidatorFormAction();

  function reset() {
    setDefaultFormValue();
    dispatch(resetAction());
  }

  return (
    <section className="-oneX-container">
      <PageTitle title="Area Rate Search Results" />
      <AverageRateTable />
      <h4>Shop Rates</h4>
      <ShopsRate />
      <article className="-oneX-row -oneX-justify-content-center mt-40">
        <div className="-oneX-col-auto">
          <Button
            id="reset"
            label="Reset"
            aria-label="Press reset button to begin search"
            onClick={reset}
          />
        </div>
      </article>
      <PageTag name="result" />
    </section>
  );
}
