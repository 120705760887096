import { createSlice } from "@reduxjs/toolkit";
import { clone } from "./helper";

const initialState = {
  name: undefined,
  touched: false,
  elements: undefined,
  defaultValue: undefined,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    /**
     * Loads form value into store.
     * @param {*} state
     * @param {*} action
     */
    initForm(state, action) {
      for (let name in action.payload) {
        state[name] = clone(action.payload[name]);
      }
    },
    /**
     * Updates form elements value.
     * @param {*} state
     * @param {*} action
     */
    updateElement(state, action) {
      const { name, data } = action.payload;
      if (state.elements && state.elements[name]) {
        state.elements[name] = data;
        state.touched = true;
      }
    },
    /**
     * Method helps to set form values at any time. But will not keep every mutation.
     * @param {*} state
     * @param {*} action
     */
    setDefaultValue(state, action) {
      state.defaultValue = action.payload;
    },
    /**
     * Resets store value to initial value.
     * @param {*} state
     */
    reset(state) {
      for (const name in initialState) {
        state[name] = initialState[name];
      }
    },
  },
});

export const selectState = (state) => state.form;
export const selectDefaultValue = (state) => state.form.defaultValue;
export const selectInput = (name) => (state) =>
  state.form.elements && state.form.elements[name];
export const { initForm, updateElement, setDefaultValue } = formSlice.actions;
export default formSlice.reducer;
