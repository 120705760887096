import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PAGE, HTTP_CODE } from "../constant";
import * as api from "./ars-api";
import { isEmptyString, hasElements } from "../utils";

const { NOT_FOUND, UN_AUTHORIZED, FORBIDDEN, INTERNAL_SERVER_ERROR } =
  HTTP_CODE;
const INIT_ITEM_COUNT = 25;
const SUGGEST_MSG = "Please make sure the information below is correct.";
const ADDRESS_ERROR_MSG = `Sorry, we could not find the address. ${SUGGEST_MSG}`;
const RATES_ERROR_MSG = `Sorry, we could not find the shop rates based on information you entered. ${SUGGEST_MSG}`;

/** Async api calls */
export const init = createAsyncThunk("api/init", api.init);
export const fetchAddress = createAsyncThunk(
  "api/fetchAddress",
  api.fetchAddress
);
export const fetchRates = createAsyncThunk("api/fetchRates", api.fetchRates);
export const keepAlive = createAsyncThunk("api/keepAlive", api.init);

/** Predicate methods */
const isPendingAction = (action) => action.type.endsWith("pending");
const isFulfilledAction = (action) => action.type.endsWith("fulfilled");
const isRejectedAction = (action) => action.type.endsWith("rejected");

/** Initial value for redux store */
const initialState = {
  /** Page component. */
  view: undefined,
  /** Model spinner */
  inProgress: true,
  /** Page level error. */
  pageErrorTxt: "",
  /** Coordinates */
  // address: { latitude: undefined, longitude: undefined, zipCode: undefined },
  rate: {
    pcp: undefined,
    average: undefined,
    simpleMajority: undefined,
    shops: [],
  },
  rateTableIndex: {
    itemsPerPage: INIT_ITEM_COUNT,
    total: 0,
    start: 0,
    end: -1,
  },
  /** Maintains api call timestamp. */
  timeIn: undefined,
};

function _reset(state, view = PAGE.SEARCH, error = "") {
  state.view = view;
  state.inProgress = false;
  state.pageErrorTxt = error;
  state.address = { ...initialState.address };
  state.rate = { ...initialState.rate };
  state.rateTableIndex = { ...initialState.rateTableIndex };
}

function _reject(state, action, errorTxt = "") {
  const statusCode = action.payload;
  if (statusCode === NOT_FOUND) {
    state.pageErrorTxt = errorTxt;
    state.view = PAGE.SEARCH;
    state.inProgress = false;
  } else if (statusCode === UN_AUTHORIZED || statusCode == FORBIDDEN) {
    _reset(state, PAGE.UN_AUTH);
  } else {
    _reset(state, PAGE.ERROR);
  }
}

/**
 * @Private
 *
 */
function _updateRateTableIndex(state, totalShops, itemsPerPage, start) {
  state.rateTableIndex.itemsPerPage = itemsPerPage;
  state.rateTableIndex.start = start;
  state.rateTableIndex.end =
    totalShops < itemsPerPage ? totalShops : itemsPerPage;
}

const _resetTimeIn = (state) => (state.timeIn = Date.now() + 1000 * 60 * 14.3);
// const _resetTimeIn = (state) => (state.timeIn = Date.now() + 1000 * 60 * 3);

/** Redux store */
const { actions, reducer } = createSlice({
  name: "app",
  initialState,
  reducers: {
    /**
     * Action for pagination when changing number record to be display on page.
     * @param {Object} state
     * @param {Object} action
     */
    setRateTableItemsPerPage(state, action) {
      const itemsPerPage = action.payload;
      const totalShops = state.rateTableIndex.total;
      _updateRateTableIndex(state, totalShops, itemsPerPage, 1);
    },
    /**
     * Action for pagination when changing page number.
     * @param {Object} state
     * @param {Object} action
     */
    setRateTableIndexes(state, action) {
      const { start, end } = action.payload;
      state.rateTableIndex.start = start;
      state.rateTableIndex.end = end;
    },
    /**
     * Action for reset button on result page.
     * @param {Object} state
     * @param {Object} action
     */
    reset(state) {
      _reset(state);
    },
  },
  extraReducers: function buildThunkReducer(builder) {
    builder
      .addCase(init.fulfilled, function applyInit(state) {
        state.pageErrorTxt = "";
        state.view = PAGE.SEARCH;
        state.inProgress = false;
      })
      .addCase(init.rejected, function rejectInit(state, action) {
        if (action.payload === NOT_FOUND) {
          _reject(state, {
            payload: INTERNAL_SERVER_ERROR,
          });
        } else {
          _reject(state, action);
        }
      })
      .addCase(fetchAddress.fulfilled, function applyAddress(state) {
        state.pageErrorTxt = "";
      })
      .addCase(fetchAddress.rejected, function rejectAddress(state, action) {
        _reject(state, action, ADDRESS_ERROR_MSG);
      })
      .addCase(fetchRates.fulfilled, function applyRates(state, action) {
        /** Average rate table update */
        const { pcp, average, simpleMajority, shops } = action.payload;
        state.rate.pcp = pcp;
        state.rate.average = average;
        state.rate.simpleMajority = simpleMajority;
        /** Shop rate table update */
        const totalShops = hasElements(shops) ? shops.length : 0;
        state.rate.shops = totalShops > 0 ? shops : [];
        state.rateTableIndex.total = totalShops;
        _updateRateTableIndex(state, totalShops, INIT_ITEM_COUNT, 1);
        /** Page */
        state.pageErrorTxt = "";
        state.view = PAGE.RESULT;
        state.inProgress = false;
      })
      .addCase(fetchRates.rejected, function rejectRates(state, action) {
        _reject(state, action, RATES_ERROR_MSG);
      })
      .addCase(keepAlive.fulfilled, function applyKeepAlive(state) {
        state.inProgress = false;
      })
      .addCase(keepAlive.rejected, function rejectRates(state, action) {
        _reject(state, action);
      })
      .addMatcher(isPendingAction, function pendingAction(state) {
        state.inProgress = true;
      })
      .addMatcher(isFulfilledAction, function fulfilledAction(state) {
        _resetTimeIn(state);
      })
      .addMatcher(isRejectedAction, function rejectedAction(state) {
        _resetTimeIn(state);
      });
  },
});

export const selectView = (state) => state.app.view;
export const selectInProgress = (state) => state.app.inProgress;
export const selectPageError = (state) => ({
  valid: !isEmptyString(state.app.pageErrorTxt),
  text: state.app.pageErrorTxt,
  tag:
    (ADDRESS_ERROR_MSG === state.app.pageErrorTxt && "address") ||
    (RATES_ERROR_MSG === state.app.pageErrorTxt && "shop-rate") ||
    "other",
});
export const selectOtherRates = (state) => ({
  pcp: state.app.rate.pcp,
  average: state.app.rate.average,
  simpleMajority: state.app.rate.simpleMajority,
});
export const selectShops =
  (start = 0, end) =>
  (state) =>
    state.app.rate.shops.slice(start - 1, end);
export const selectRateTableIndexes = (state) => state.app.rateTableIndex;
export const selectTimeIn = (state) => state.app.timeIn;
export const { setRateTableItemsPerPage, setRateTableIndexes, reset } = actions;
export default reducer;
