import React from "react";
import { isFn } from "../utils";

export default function TimeoutModel({
  id,
  visible,
  timerText,
  onExtendClick,
  onLogoutClick,
}) {
  const btnRef = React.useRef();
  const yesRef = React.useRef();
  const noRef = React.useRef();

  function show() {
    window.oneX.Modal.showModal(btnRef, id);
  }

  function hide() {
    window.oneX.Modal.hideSessionModal();
  }

  function doExtend() {
    isFn(onExtendClick) && onExtendClick();
  }

  function doLogout() {
    isFn(onLogoutClick) && onLogoutClick();
  }

  React.useEffect(function mount() {
    yesRef.current.addEventListener("click", doExtend);
    noRef.current.addEventListener("click", doLogout);

    return function unMount() {
      yesRef.current.removeEventListener("click", doExtend);
      noRef.current.removeEventListener("click", doLogout);
    };
  }, []);

  React.useEffect(
    function mount() {
      void (visible ? show : hide)();
      return function unMount() {};
    },
    [visible]
  );

  return (
    <div className="-oneX">
      <button ref={btnRef} style={{ display: "none" }}></button>
      <div
        id={id}
        data-session-timeout="true"
        className="-oneX-modal__container"
        style={{ display: "none" }}
      >
        <div
          className="-oneX-modal-content"
          aria-labelledby="dialogAction"
          role="dialog"
        >
          <div
            id="dialogAction"
            className="-oneX-modal-heading"
            aria-level="2"
            role="heading"
            tabIndex="-1"
          >
            Are you still there?
          </div>
          <div className="-oneX-modal-body">
            Your session will expire in
            <span role="timer" aria-atomic="true" aria-live=" ">
              {timerText}
            </span>
            . Would you like to extend the session?
          </div>
          <div className="-oneX-modal-footer">
            <div className="-oneX-row">
              <div className="-oneX-modal-primary__div -oneX-col">
                <button ref={yesRef} className="-oneX-btn-primary">
                  Extend
                </button>
              </div>
              <div className="-oneX-modal-secondary__div -oneX-col">
                <button ref={noRef} className="-oneX-btn-secondary">
                  Log Out
                </button>
              </div>
            </div>
          </div>
          {/* <button
            className="-oneX-close-X-button"
            aria-label="Close Session Time Out"
          >
            <span className="-oneX-icon--close"> </span>
          </button> */}
        </div>
      </div>
    </div>
  );
}
