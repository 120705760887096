import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { TimeoutModel, PageTag } from "../components";
import { selectTimeIn, keepAlive } from "../app/app-slice";

/** Redirect URL when user not found. */
const LOGOUT_REDIRECT = "../../Pkmslogout";

/** times in millisecond */
const TIME_MS_SEC_05 = 1000 * 5;
const TIME_MS_SEC_30 = 1000 * 30;
const TIME_MS_MINUTE_02 = 1000 * 60 * 2;

function getTimeStr(ms, id) {
  const second = ms / 1000;
  const mins = Math.trunc(second / 60);
  const secs = Math.trunc(second - mins * 60);
  const timeStr = ` ${Math.trunc(mins)} minute ${Math.ceil(secs)} seconds `;
  if (id) {
    console.log(`${id}: ${timeStr}`);
  }
  return timeStr;
}

function createTimer(ms, fn) {
  return (function () {
    let timerId;
    function _clear() {
      clearInterval(timerId);
      timerId = undefined;
    }
    return {
      start() {
        timerId = setInterval(fn, ms);
      },
      stop() {
        if (timerId) {
          _clear();
        }
      },
    };
  })();
}

function TimeoutPopUp({ enable, timeIn, onLogout, onExtend }) {
  const [timerText, setTimerText] = React.useState(" 2 minutes ");

  React.useEffect(
    function mount() {
      const timer = createTimer(TIME_MS_SEC_05, function execute() {
        const ms = timeIn - Date.now();
        setTimerText(getTimeStr(ms));
        if (ms < 0) {
          timer.stop();
          setTimerText(getTimeStr(0));
          return onLogout();
        }
      });

      if (enable) {
        timer.start();
      }

      return function unMount() {
        timer.stop();
      };
    },
    [enable]
  );

  return (
    <>
      <TimeoutModel
        id="timeout-mc-popup"
        visible={enable}
        timerText={timerText}
        onExtendClick={onExtend}
        onLogoutClick={onLogout}
      />
      {enable && <PageTag name="session-timeout-popup" />}
    </>
  );
}

function SessionTimeout(props) {
  const { timeIn } = props;
  const [isExpire, setExpire] = React.useState();

  React.useEffect(
    function mount() {
      setExpire(false);
      const timer = createTimer(TIME_MS_SEC_30, function execute() {
        const ms = timeIn - Date.now();
        if (TIME_MS_MINUTE_02 > ms) {
          setExpire(true);
          timer.stop();
        }
      });
      timer.start();

      return function unMount() {
        timer.stop();
      };
    },
    [timeIn]
  );

  return <TimeoutPopUp enable={isExpire} {...props} />;
}

export default function SessionTimeoutWrapper() {
  const dispatch = useDispatch();
  const timeIn = useSelector(selectTimeIn);

  function logout() {
    window.location.replace(LOGOUT_REDIRECT);
  }

  function extend() {
    dispatch(keepAlive());
  }

  return (
    !isNaN(timeIn) && (
      <SessionTimeout timeIn={timeIn} onLogout={logout} onExtend={extend} />
    )
  );
}
