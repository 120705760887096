import React from "react";
import "./spinner.css";

function _Spinner({ message }) {
  return (
    <article className="f-column" tabIndex="-1">
      <span className="-oneX-body--primary">{message || "Please wait..."}</span>
      <span>&nbsp;</span>
      <svg className="loader">
        <circle cx="40" cy="40" r="36"></circle>
      </svg>
    </article>
  );
}

export function ModelSpinner({ message }) {
  return (
    <section id="model-spinner">
      <div
        data-scrim-modal=""
        className="-oneX--scrim__modal -oneX--scrim__on"
      ></div>
      <div className="-oneX -oneX-modal__container">
        <div
          className="-oneX-modal-content"
          aria-labelledby="dialogNoAction"
          role="dialog"
          style={{ background: "none" }}
        >
          <div className="-oneX-modal-body f-center">
            <_Spinner message={message} />
          </div>
        </div>
      </div>
    </section>
  );
}

export function InlineSpinner({ message }) {
  return (
    <section className="-oneX-container">
      <div className="-oneX-page-loader" tabIndex="-1">
        <_Spinner message={message} />
      </div>
    </section>
  );
}
