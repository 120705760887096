import validateFn from "./validate-fn";
import { isFun } from "./helper";

const REGEX_SAFE_STR = /[^a-zA-Z0-9 (),._%@:!&"'\-$]/g;

function size(defaultError, length, error) {
  this.validators.push({
    fn: validateFn.SIZE(length),
    msg: error || defaultError,
  });
  return this;
}

function min(defaultError, size, error) {
  this.validators.push({
    fn: validateFn.MIN(size),
    msg: error || defaultError,
  });
  return this;
}

function max(defaultError, size, error) {
  this.validators.push({
    fn: validateFn.MAX(size),
    msg: error || defaultError,
  });
  return this;
}

function range(defaultError, start, end, error) {
  const msg = error || defaultError;
  this.validators.push({ fn: validateFn.LT(start), msg });
  if (end && -1 < end) {
    this.validators.push({ fn: validateFn.GT(end), msg });
  }
  return this;
}

function validate(defaultError, fn, error) {
  this.validators.push({ fn: fn, msg: error || defaultError });
  return this;
}

const _allow = (regex) => (value) =>
  value ? ("" + value).replace(regex, "") : "";

function allowWith(prefix, ...chars) {
  this.filters.push(_allow(new RegExp(`[^${prefix}${chars.join("")}]+`, "g")));
  return this;
}

/**
 * Adds filter function in chain.
 * @param {function | regex} regex
 * @returns
 */
function allowOnly(regex) {
  this.filters.push(isFun(regex) ? regex : _allow(regex));
  return this;
}

function inputBuilder(defaultError, element = {}) {
  element.validators = [];
  element.filters = [];
  //todo: format
  element.format = undefined;
  // todo: SETlENGTH
  // validate fns
  element.validate = validate.bind(element, defaultError);
  element.required = validate.bind(element, defaultError, validateFn.REQUIRED);
  element.size = size.bind(element, defaultError);
  element.min = min.bind(element, defaultError);
  element.max = max.bind(element, defaultError);
  element.claimNumber = validate.bind(
    element,
    defaultError,
    validateFn.CLAIM_NUM
  );
  element.phone = validate.bind(element, defaultError, validateFn.PHONE);
  element.zip = validate.bind(element, defaultError, validateFn.ZIP);
  element.email = validate.bind(element, defaultError, validateFn.EMAIL);
  element.range = range.bind(element, defaultError);

  // allow fns [aka filters]
  element.allow = allowOnly.bind(element);
  element.allowSafeString = allowOnly.bind(element, REGEX_SAFE_STR);
  element.allowWordsOnly = allowOnly.bind(element, /[^a-zA-Z]/g);
  element.allowWordsWith = allowWith.bind(element, "a-zA-Z");
  element.allowWordsAndNumbersWith = allowWith.bind(element, "\\d\\w");
  element.allowOnlyNumbers = allowOnly.bind(element, /[^\d]/g);
  element.allowNumbersWith = allowWith.bind(element, "\\d");

  return element;
}

function input(name, defaultError) {
  this.elements[name] = inputBuilder(defaultError);
  return this.elements[name];
}

/**
 * Build form.
 * Structure:
 * {
 *   [name]: {
 *      name: string
 *      validate: function
 *      hasError: bool
 *      errorText: string
 *      value: string
 *      tuched: false
 *    }
 * }
 *
 * @param {*} name
 * @param {*} elements
 * @returns
 */
export default function formBuilder(name = "defaultForm", elements = {}) {
  return ((form) => {
    form.input = input.bind(form);
    return form;
  })({ name, elements });
}
