import React from "react";

import { isStr } from "../utils";

/** Screen name */
const SCREEN_CHANNEL = "b2b-area-rate-search";

/**
 * Create screen change event.
 */
const createTaggingEvent = new Event("screenChange", {
  bubbles: true,
  cancelable: false,
});

function triggerSurvey(screenId) {
  if (isStr(screenId) && screenId.includes("confirmation")) {
    setTimeout(function waitForSurveyTrigger() {
      window.KAMPYLE_ONSITE_SDK &&
        window.KAMPYLE_ONSITE_SDK.updatePageView &&
        window.KAMPYLE_ONSITE_SDK.updatePageView();
    }, 6000);
  }
}
/**
 * Set data layer and trigger event.
 * @param {String} screenId
 */
function applyEvent(screenId) {
  if (window.dataLayer) {
    window.dataLayer.screenChannel = SCREEN_CHANNEL;
    window.dataLayer.screenId = screenId;
    window.dispatchEvent(createTaggingEvent);
    triggerSurvey(screenId);
  }
}

/**
 * Page Tag component
 * @param {Object} param0
 * @returns
 */
export default function PageTag({ name }) {
  React.useEffect(() => applyEvent(name), []);
  return <noscript>{`Page Name: ${name}`}</noscript>;
}
