import React from "react";

export function PageTitle({
  title,
  subTitle,
  subTitleClass,
  summary,
  note,
  className,
} = {}) {
  if (title && document) {
    document.title = title;
  }

  return (
    <header className={className}>
      {title && (
        <h1 id="page-title" className="border-btm">
          {title}
        </h1>
      )}
      {subTitle && (
        <p
          id="page-sub-title"
          className={(subTitleClass && subTitleClass) || "-oneX-body--intro"}
        >
          {subTitle}
        </p>
      )}
      {summary && (
        <p id="page-summary" className="-oneX-body--primary">
          {summary}
        </p>
      )}
      {note && (
        <p className="-oneX-body--secondary -oneX-font-Mecherle">{note}</p>
      )}
    </header>
  );
}
