import React from "react";

import { ErrorText } from "./error";
import { util, useInputControl } from "../validator";
const { throwErr, isEmptyObj } = util;

function toOption(v = "", l = "", d) {
  return (
    <option key={v} value={v} disabled={d}>
      {l}
    </option>
  );
}

export function Option({ value, label, disabled, list }) {
  if (list && Array.isArray(list)) {
    return list.map((val) => {
      const [key, label] = val.split(":");
      return toOption(key, label || key, false);
    });
  } else if (list && typeof list === "object") {
    return Object.entries(list).map(([key, label]) =>
      toOption(key, label, false)
    );
  } else {
    return toOption(value, label, disabled);
  }
}

export default function Select({ id, label, className, children, element }) {
  throwErr(isEmptyObj(element), "Element property required in Text.");
  const { name, value, isError, errorText, touched, setValue } =
    useInputControl(element);
  const error = touched && isError;
  // styles
  const styleClass = `${className} ${error && "-oneX-widget--err"}`;

  return (
    <div className={styleClass}>
      <label htmlFor={name} className="-oneX-dropdown__floatingLabel">
        {label}
      </label>
      <select
        id={id}
        name={name}
        type="text"
        aria-invalid="true"
        aria-describedby="floatingLabelDropdown-err"
        className="-oneX-dropdown"
        onChange={(e) => setValue({ value: e.target.value, touched })}
        onBlur={(e) => setValue({ value: e.target.value, touched: true })}
        // defaultValue={value}
        value={value}
      >
        {children}
      </select>
      {error && <ErrorText id={`error-alert-${name}`} text={errorText} />}
    </div>
  );
}
