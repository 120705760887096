import { formBuilder } from "../../validator";

const form = formBuilder("searchForm");
form
  .input("address", "Address is required.")
  .required()
  .min(4, "Minimum 4 characters required.")
  .max(100, "Can't exceed character.")
  .allowWordsAndNumbersWith(" ");
form
  .input("zipCode", "Zip code is required.")
  .required()
  .zip("Enter a valid Zip code.")
  .allowNumbersWith("-");
form
  .input("radius", "Radius is required.")
  .required()
  .range(1, 100, "Radius value must be between 0 to 100.")
  .allowOnlyNumbers();
form.input("numOfShop", "Number of shops required.").required();

export default form;
