import React from "react";

export function PageError({ text } = {}) {
  return (
    <div className="-oneX-notification">
      <article className="-oneX-notification__icon -oneX-notification--error">
        <span
          className="-oneX-icon--error"
          aria-label="error"
          role="img"
        ></span>
      </article>
      <article
        className="-oneX-notification__text"
        style={{ whiteSpace: "pre-line" }}
      >
        {text}
      </article>
    </div>
  );
}
