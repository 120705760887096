/**
 * Pipe into two function.
 * @param  {[function]} f [function A]
 * @param  {[function]} g [function B]
 * @return {[function]}   [pipe function]
 */
export const pipe = (f, g) => (arg) => g(f(arg));

/**
 * Pipe into multiple function.
 * @param  {[type]} fns [description]
 * @return {[type]}     [description]
 */
export const pipes = (...fns) =>
  fns.filter((ele) => ele && typeof ele === "function").reduce(pipe);

/**
 * Return true if arg is an function type.
 * @param {Function} fn
 * @returns
 */
export const isFun = (fn) => fn && "function" === typeof fn;

export const isObj = (obj) => obj && "object" === typeof obj;

export const isEmptyStr = (value) => !value || value.trim().length === 0;

export const isEmptyObj = (obj) => !obj || Object.keys(obj).length === 0;

export const isEmptyArr = (arg) => Array.isArray(arg) && 0 === arg.length;

export const reduceObj = (acc, cur) => Object.assign(acc, cur);

export const throwErr = (condition, error) => {
  if (condition) throw error;
};

export function clone(obj) {
  if (obj === null || typeof obj !== "object" || "isActiveClone" in obj)
    return obj;
  let temp =
    (obj instanceof Date && new obj.constructor()) || obj.constructor();
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj["isActiveClone"] = null;
      temp[key] = clone(obj[key]);
      delete obj["isActiveClone"];
    }
  }
  return temp;
}
