import React from "react";

export function Header({ auth }) {
  const thisRef = React.useRef();
  const type = React.useMemo(
    () => (auth ? "b2b-header-auth-limited" : "b2b-header-unauth"),
    [auth]
  );
  React.useEffect(() => {
    if (window._b2b) window._b2b.Header.add();
    return () => {
      if (window._b2b) window._b2b.Header.remove();
    };
  }, [auth]);

  return (
    <div ref={thisRef}>
      <header data-type={type}></header>
    </div>
  );
}
