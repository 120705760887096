import React from "react";
import onexConnect from "./onex-connect";

function IncrementorItem({ curItem, itemsPerPage, onItemsPerPageChange }) {
  if (itemsPerPage === curItem) {
    return (
      <li>
        <a
          className="-oneX-pagination--active"
          aria-label={`showing ${curItem} results`}
          aria-current="true"
          aria-live="polite"
          aria-disabled="true"
        >
          {curItem}
        </a>
      </li>
    );
  } else {
    return (
      <li>
        <a
          aria-label={`showing ${curItem} results`}
          onClick={() => onItemsPerPageChange(curItem)}
        >
          {curItem}
        </a>
      </li>
    );
  }
}

function Incrementor({ totalItems, itemsPerPage, onItemsPerPageChange }) {
  const incTotal = totalItems / 25;
  const increment = totalItems % 25 > 0 ? 1 : 0;
  const recPage = incTotal < 4 ? Math.floor(incTotal) + increment : 4;
  const increments = Array(recPage)
    .fill(0)
    .map((e, i) => (i + 1) * 25);
  return (
    <div className="-oneX-pagination-page-increments">
      <div className="-oneX-pagination-showingText">Showing</div>
      <ul>
        {increments.map((item, i) => (
          <IncrementorItem
            key={`incrementor-item-${i}`}
            curItem={item}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={onItemsPerPageChange}
          />
        ))}
      </ul>
    </div>
  );
}

function Pagination({
  id,
  totalItems,
  itemsPerPage,
  onItemsPerPageChange,
  onPageNumberChange,
}) {
  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    oneX.Pagination.updatePagination(`#${id}`);
    return () => {
      //   OneX.element.remove(thisRef);
    };
  }, [itemsPerPage]);

  //todo: add keys filter event.
  function setPageNumber() {
    const paginationIndexer = document.querySelector(
      "[data-pagination='indexer']"
    );

    const startIdx = paginationIndexer
      .querySelector('[data-page="range"]')
      .innerText.match(/\d+-\d+/)[0]
      .split("-")[0];
    const endIdx = paginationIndexer
      .querySelector('[data-page="range"]')
      .innerText.match(/\d+-\d+/)[0]
      .split("-")[1];
    // const itemsInPage = paginationIndexer.dataset.numberOfItems;
    onPageNumberChange && onPageNumberChange(startIdx, endIdx);
  }

  return (
    <>
      <Incrementor
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        onItemsPerPageChange={onItemsPerPageChange}
      />
      <div
        id={id}
        data-number-of-items={totalItems}
        data-current-items-per-page={itemsPerPage}
        // data-page-start="2"
        data-pagination="indexer"
        aria-label="Pagination"
      >
        <nav className="-oneX-pagination-pages" role="navigation">
          <div data-page="range"></div>
          <ul>
            <li>
              <a
                data-chevron="prev"
                role="link"
                className="-oneX-pagination--prev--disabled"
                aria-disabled="true"
                aria-label="previous"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                className="-oneX-pagination--active"
                data-page="start"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li data-ellipsis="left" className="-oneX-hidden">
              <span>...</span>
            </li>
            <li>
              <a
                data-page="small_list-index2"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                data-page="small_list-index3"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                data-page="small_list-index4"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                data-page="small_list-index5"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                data-page="small_list-index6"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                data-page="index1"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                data-page="index2"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                data-page="index3"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li data-ellipsis="right" className="-oneX-hidden">
              <span>...</span>
            </li>
            <li>
              <a
                data-page="end"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
            <li>
              <a
                data-chevron="next"
                role="link"
                className="-oneX-pagination--next--disabled"
                aria-disabled="true"
                aria-label="next"
                onClick={setPageNumber}
                onKeyDown={setPageNumber}
              ></a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default onexConnect()(Pagination);
