/**
 * formatFn - Set of plug able formatter that can be apply InputControl.
 */
import { pipes } from "./helper";

const formatFn = (regexp, format) => (value) =>
  regexp.test(value) ? value.replace(regexp, format) : value;
/**
 * Accepts regular expressions and format.
 * @param {regexp} regexp RegExp
 */
const filterFn = (regexp) => (value) =>
  value ? ("" + value).replace(regexp, "") : "";

/**
 *  Format if regex matches value otherwise returns value
 * @param  {[RegExp]} matcher [Formate matcher]
 * @param  {[String]} format  [Desire format]
 * @param  {[RegExp]} filter  [exclude pattern]
 * @return {[Function]}         [Function that accepts value]
 */
const format = ({ matcher, format, filter }) => {
  const _filter = filter && filterFn(filter);
  const _formatter = pipes(_filter, formatFn(matcher, format));
  return (value) => _formatter(value);
};
// TODO: test flexible format
const PHONE = format({
  matcher: /^(\d{3})(\d{3})(\d{1,4})$/,
  format: "($1) $2-$3",
});

// TODO: test flexible format
const ZIP = format({
  matcher: /^(\d{5})(\d{1,4})$/,
  format: "$1-$2",
});

const CLAIM_NUM = format({
  matcher: /^([0-9]{2})([a-zA-Z0-9]{1,4})?([a-zA-Z0-9]{3})?/,
  format: "$1-$2-$3",
  filter: /[\W]/g,
});

// TODO: add comma filter
const CURRENCY = (value) => {
  const _val = Number(value);
  return 0 === _val || isNaN(_val) ? "" : "$" + _val.format(2);
};

export default { format, PHONE, CLAIM_NUM, CURRENCY, ZIP };
